// @ts-nocheck
import { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { routeConfig, publicRoutes } from './routes.config';
import PrivateRoute from './PrivateRoute';
import LoadingSpinner from '../components/ui/LoadingSpinner';

const AppRoutes = () => {
  const renderPublicRoutes = () => {
    return Object.entries(publicRoutes).map(([key, route]) => (
      <Route
        key={route.path}
        path={route.path}
        element={<route.component />}
      />
    ));
  };

  const renderPrivateRoutes = (routes: typeof routeConfig) => {
    return Object.entries(routes).flatMap(([routeKey, route]) => {
      if (route.children) {
        return [
          <Route
            key={route.path}
            path={route.path}
            element={
              <PrivateRoute>
                <route.component />
              </PrivateRoute>
            }
          />,
          ...Object.entries(route.children).map(([childKey, childRoute]) => (
            <Route
              key={`${route.path}-${childRoute.path}`}
              path={childRoute.path}
              element={
                <PrivateRoute>
                  <childRoute.component />
                </PrivateRoute>
              }
            />
          ))
        ];
      }
      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            <PrivateRoute>
              <route.component />
            </PrivateRoute>
          }
        />
      );
    });
  };

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        {renderPublicRoutes()}
        {renderPrivateRoutes(routeConfig)}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
