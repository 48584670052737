import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SidebarState {
    isOpen: boolean;
}

const initialState: SidebarState = {
    isOpen: true,
};

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.isOpen = !state.isOpen;
        },
        setSidebarOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
    },
});

export const { toggleSidebar, setSidebarOpen } = sidebarSlice.actions;

export default sidebarSlice.reducer;
