import axios from 'axios';
import { URL_BASE } from '../config/config';

export const apiClient = {
    get: async (endpoint: string, params = {}, headers = {}) => {
        const response = await axios.get(`${URL_BASE}${endpoint}`, { params, headers });
        return response.data;
    },
    post: async (endpoint: string, body = {}, headers = {}) => {
        const response = await axios.post(`${URL_BASE}${endpoint}`, body, { headers });
        return response.data;
    },
    put: async (endpoint: string, body = {}, headers = {}) => {
        const response = await axios.put(`${URL_BASE}${endpoint}`, body, { headers });
        return response.data;
    },
    delete: async (endpoint: string, headers = {}) => {
        const response = await axios.delete(`${URL_BASE}${endpoint}`, { headers });
        return response.data;
    },
};
