import { lazy } from 'react';

// Lazy loading de componentes
const Dashboard = lazy(() => import('../pages/Dashboard').then(module => ({ default: module.Dashboard })));
const Inventory = lazy(() => import('../pages/inventary').then(module => ({ default: module.Inventory })));
const ProductBase = lazy(() => import('../pages/product_base').then(module => ({ default: module.ProductBase })));
const Orders = lazy(() => import('../pages/orders').then(module => ({ default: module.Orders })));
const ProductTemplates = lazy(() => import('../pages/inventary/products').then(module => ({ default: module.ProductTemplates })));
const Customers = lazy(() => import('../pages/customer').then(module => ({ default: module.Customers })));
const Suppliers = lazy(() => import('../pages/supplier').then(module => ({ default: module.Supplier })));
const Brands = lazy(() => import('../pages/brands').then(module => ({ default: module.Brand })));
const Carriers = lazy(() => import('../pages/carriers').then(module => ({ default: module.Carriers })));
const Countries = lazy(() => import('../pages/geo/country').then(module => ({ default: module.Countries })));
const States = lazy(() => import('../pages/geo/state').then(module => ({ default: module.States })));
const Communes = lazy(() => import('../pages/geo/communes').then(module => ({ default: module.Cities })));
const Companies = lazy(() => import('../pages/companies').then(module => ({ default: module.Companies })));
const Categories = lazy(() => import('../pages/categories').then(module => ({ default: module.Categories })));
const Roles = lazy(() => import('../pages/config/roles').then(module => ({ default: module.Roles })));
const Payments = lazy(() => import('../pages/payment').then(module => ({ default: module.Payment })));
const PaymentMethods = lazy(() => import('../pages/payment_method').then(module => ({ default: module.PaymentMethods })));
const Tags = lazy(() => import('../pages/tags').then(module => ({ default: module.Tags })));
const TaxClassifications = lazy(() => import('../pages/taxclassification').then(module => ({ default: module.TaxClassifications })));
const Taxes = lazy(() => import('../pages/taxes').then(module => ({ default: module.Taxes })));
const Users = lazy(() => import('../pages/users').then(module => ({ default: module.Users })));
const Budget = lazy(() => import('../pages/budget').then(module => ({ default: module.Budget })));
const Purchase = lazy(() => import('../pages/purchase').then(module => ({ default: module.Purchase })));

// Configuración de rutas
export const routeConfig = {
    dashboard: {
        path: '/dashboard',
        component: Dashboard,
        title: 'Dashboard',
        icon: 'DashboardIcon',
    },
    inventory: {
        path: '/inventario',
        component: Inventory,
        title: 'Inventario',
        icon: 'InventoryIcon',
        children: {
            create: {
                path: '/inventario/create',
                component: lazy(() => import('../pages/inventary/CreateInventary').then(module => ({ default: module.CreateInventary }))),
                title: 'Crear Inventario',
            },
            edit: {
                path: '/inventario/edit/:id',
                component: lazy(() => import('../pages/inventary/CreateInventary').then(module => ({ default: module.CreateInventary }))),
                title: 'Editar Inventario',
            },
        },
    },
    products: {
        path: '/product-templates',
        component: ProductTemplates,
        title: 'Plantillas de Productos',
        icon: 'ProductIcon',
        children: {
            create: {
                path: '/product-templates/create',
                component: lazy(() => import('../pages/inventary/products/ProductManagement').then(module => ({ default: module.ProductManagement }))),
                title: 'Crear Plantilla',
            },
            edit: {
                path: '/product-templates/edit/:id',
                component: lazy(() => import('../pages/inventary/products/ProductManagement').then(module => ({ default: module.ProductManagement }))),
                title: 'Editar Plantilla',
            },
            view: {
                path: '/product-templates/view/:id',
                component: lazy(() => import('../pages/inventary/products/ProductManagement').then(module => ({ default: module.ProductManagement }))),
                title: 'Ver Plantilla',
            },
        },
    },
    products_base: {
        path: '/products',
        component: ProductBase,
        title: 'Productos',
        icon: 'ProductIcon',
        children: {
            create: {
                path: '/products/create',
                component: lazy(() => import('../pages/product_base/CreateProductBase').then(module => ({ default: module.CreateProductBase }))),
                title: 'Crear Producto',
            },
            edit: {
                path: '/products/edit/:id',
                component: lazy(() => import('../pages/product_base/CreateProductBase').then(module => ({ default: module.CreateProductBase }))),
                title: 'Editar Producto',
            },
            view: {
                path: '/products/view/:id',
                component: lazy(() => import('../pages/product_base/CreateProductBase').then(module => ({ default: module.CreateProductBase }))),
                title: 'Ver Producto',
            },
        },
    },
    orders: {
        path: '/orders',
        component: Orders,
        title: 'Órdenes',
        icon: 'OrdersIcon',
        children: {
            create: {
                path: '/orders/create',
                component: lazy(() => import('../pages/orders/OrderManagement')),
                title: 'Crear Orden',
            },
            edit: {
                path: '/orders/edit/:id',
                component: lazy(() => import('../pages/orders/OrderManagement')),
                title: 'Editar Orden',
            },
            view: {
                path: '/orders/view/:id',
                component: lazy(() => import('../pages/orders/OrderManagement')),
                title: 'Ver Orden',
            },
        },
    },
    budget: {
        path: '/budgets',
        component: Budget,
        title: 'Presupuestos',
        icon: 'BudgetIcon',
        children: {
            create: {
                path: '/budgets/create',
                component: lazy(() => import('../pages/budget/BudgetManagement')),
                title: 'Crear Presupuesto',
            },
            edit: {
                path: '/budgets/edit/:id',
                component: lazy(() => import('../pages/budget/BudgetManagement')),
                title: 'Editar Presupuesto', 
            },
            view: {
                path: '/budgets/view/:id',
                component: lazy(() => import('../pages/budget/BudgetManagement')),
                title: 'Ver Presupuesto',
            },
        },
    },
    purchases: {
        path: '/purchases',
        component: Purchase,
        title: 'Compras',
        icon: 'PurchasesIcon',
        children: {
            create: {
                path: '/purchases/create',
                component: lazy(() => import('../pages/purchase/PurchaseManagement')),
                title: 'Crear Compra',
            },
            edit: {
                path: '/purchases/edit/:id',
                component: lazy(() => import('../pages/purchase/PurchaseManagement')),
                title: 'Editar Compra',
            },
            view: {
                path: '/purchases/view/:id',
                component: lazy(() => import('../pages/purchase/PurchaseManagement')),
                title: 'Ver Compra',
            },
        },
    },

    customers: {
        path: '/customers',
        component: Customers,
        title: 'Clientes',
        icon: 'CustomersIcon',
        children: {
            create: {
                path: '/customers/create',
                component: lazy(() => import('../pages/customer/CustomerManagement').then(module => ({ default: module.CustomerManagement }))),
                title: 'Crear Cliente',
            },
            edit: {
                path: '/customers/edit/:id',
                component: lazy(() => import('../pages/customer/CustomerManagement').then(module => ({ default: module.CustomerManagement }))),
                title: 'Editar Cliente',
            },
            view: {
                path: '/customers/view/:id',
                component: lazy(() => import('../pages/customer/CustomerManagement').then(module => ({ default: module.CustomerManagement }))),
                title: 'Ver Cliente',
            }
        }
    },
    suppliers: {
        path: '/suppliers',
        component: Suppliers,
        title: 'Proveedores',
        icon: 'SuppliersIcon',
        children: {           
            create: {
                path: '/suppliers/create',
                component: lazy(() => import('../pages/supplier/SupplierManagement').then(module => ({ default: module.SupplierManagement }))),
                title: 'Crear Proveedor',
            },
            edit: {
                path: '/suppliers/edit/:id',
                component: lazy(() => import('../pages/supplier/SupplierManagement').then(module => ({ default: module.SupplierManagement }))),
                title: 'Editar Proveedor',
            },
            view: {
                path: '/suppliers/view/:id',
                component: lazy(() => import('../pages/supplier/SupplierManagement').then(({ SupplierManagement }) => ({ default: SupplierManagement }))),
                title: 'Ver Proveedor',
            }
        }
    },
    brands: {
        path: '/brands',
        component: Brands,
        title: 'Marcas',
        icon: 'BrandsIcon',
        children: {
            create: {
                path: '/brands/create',
                component: lazy(() => import('../pages/brands/CreateBrand').then(module => ({ default: module.CreateBrand }))),
                title: 'Crear Marca',
            },
            edit: {
                path: '/brands/edit/:id',
                component: lazy(() => import('../pages/brands/CreateBrand').then(module => ({ default: module.CreateBrand }))),
                title: 'Editar Marca',
            },
            view: {
                path: '/brands/view/:id',
                component: lazy(() => import('../pages/brands/CreateBrand').then(module => ({ default: module.CreateBrand }))),
                title: 'Ver Marca',
            }
        }
    },
    carriers: {
        path: '/carriers',
        component: Carriers,
        title: 'Transportistas',
        icon: 'CarriersIcon',
        children: {
            create: {
                path: '/carriers/create',
                component: lazy(() => import('../pages/carriers/CarrierManagement').then(module => ({ default: module.CarrierManagement }))),
                title: 'Crear Transportista',
            },
            edit: {
                path: '/carriers/edit/:id',
                component: lazy(() => import('../pages/carriers/CarrierManagement').then(module => ({ default: module.CarrierManagement }))),
                title: 'Editar Transportista',
            },
            view: {
                path: '/carriers/view/:id',
                component: lazy(() => import('../pages/carriers/CarrierManagement').then(module => ({ default: module.CarrierManagement }))),
                title: 'Ver Transportista',
            }
        }
    },
    countries: {
        path: '/countries',
        component: Countries,
        title: 'Países',
        icon: 'GeoIcon',
        children: {
            create: {
                path: '/countries/create',
                component: lazy(() => import('../pages/geo/country/CreateCountry').then(module => ({ default: module.CreateCountry }))),
                title: 'Crear País',
            },
            edit: {
                path: '/countries/edit/:id',
                component: lazy(() => import('../pages/geo/country/CreateCountry').then(module => ({ default: module.CreateCountry }))),
                title: 'Editar País',
            },
            view: {
                path: '/countries/view/:id',
                component: lazy(() => import('../pages/geo/country/CreateCountry').then(module => ({ default: module.CreateCountry }))),
                title: 'Ver País',
            }
        }
    },
    states: {
        path: '/geo/states',
        component: States,
        title: 'Regiones',
        icon: 'GeoIcon',
        children: {
            create: {
                path: '/states/create',
                component: lazy(() => import('../pages/geo/state/CreateState').then(module => ({ default: module.CreateState }))),
                title: 'Crear Región',
            },
            edit: {
                path: '/states/edit/:id',
                component: lazy(() => import('../pages/geo/state/CreateState').then(module => ({ default: module.CreateState }))),
                title: 'Editar Región',
            },
            view: {
                path: '/states/view/:id',
                component: lazy(() => import('../pages/geo/state/CreateState').then(module => ({ default: module.CreateState }))),
                title: 'Ver Región',
            }
        }
    },
    communes: {
        path: '/communes',
        component: Communes,
        title: 'Comunas',
        icon: 'GeoIcon',
        children: {
            create: {
                path: '/communes/create',
                component: lazy(() => import('../pages/geo/communes/CreateCommune').then(module => ({ default: module.CreateCity }))),
                title: 'Crear Comuna',
            },
            edit: {
                path: '/communes/edit/:id',
                component: lazy(() => import('../pages/geo/communes/CreateCommune').then(module => ({ default: module.CreateCity }))),
                title: 'Editar Comuna',
            },
            view: {
                path: '/communes/view/:id',
                component: lazy(() => import('../pages/geo/communes/CreateCommune').then(module => ({ default: module.CreateCity }))),
                title: 'Ver Comuna',
            }
        }
    },
    companies: {
        path: '/companies',
        component: Companies,
        title: 'Empresas',
        icon: 'CompaniesIcon',
        children: {
            create: {
                path: '/companies/create',
                component: lazy(() => import('../pages/companies/CompanyManagement').then(module => ({ default: module.CompanyManagement }))),
                title: 'Crear Empresa',
            },
            edit: {
                path: '/companies/edit/:id',
                component: lazy(() => import('../pages/companies/CompanyManagement').then(module => ({ default: module.CompanyManagement }))),
                title: 'Editar Empresa',
            },
            view: {
                path: '/companies/view/:id',
                component: lazy(() => import('../pages/companies/CompanyManagement').then(module => ({ default: module.CompanyManagement }))),
                title: 'Ver Empresa',
            },
        }
    },
    categories: {
        path: '/categories',
        component: Categories,
        title: 'Categorías',
        icon: 'CategoriesIcon',
        children: {
            create: {
                path: '/categories/create',
                component: lazy(() => import('../pages/categories/CreateCategoy').then(module => ({ default: module.CreateCategory }))),
                title: 'Crear Categoría',
            },
            edit: {
                path: '/categories/edit/:id',
                component: lazy(() => import('../pages/categories/CreateCategoy').then(module => ({ default: module.CreateCategory }))),
                title: 'Editar Categoría',
            },
            view: {
                path: '/categories/view/:id',
                component: lazy(() => import('../pages/categories/CreateCategoy').then(module => ({ default: module.CreateCategory }))),
                title: 'Ver Categoría',
            }
        }
    },
    roles: {
        path: '/roles',
        component: Roles,
        title: 'Roles',
        icon: 'RolesIcon',
        children: {
            create: {
                path: '/roles/create',
                component: lazy(() => import('../pages/config/roles/CreateRole').then(module => ({ default: module.CreateRole }))),
                title: 'Crear Rol',
            },
            edit: {
                path: '/roles/edit/:id',
                component: lazy(() => import('../pages/config/roles/CreateRole').then(module => ({ default: module.CreateRole }))),
                title: 'Editar Rol',
            },
            view: {
                path: '/roles/view/:id',
                component: lazy(() => import('../pages/config/roles/CreateRole').then(module => ({ default: module.CreateRole }))),
                title: 'Ver Rol',
            }
        }
    },
    payments: {
        path: '/payment',
        component: Payments,
        title: 'Pagos',
        icon: 'PaymentsIcon',
    },
    paymentMethods: {
        path: '/payment-method',
        component: PaymentMethods,
        title: 'Métodos de Pago',
        icon: 'PaymentMethodsIcon',
    },
    tags: {
        path: '/tags',
        component: Tags,
        title: 'Etiquetas',
        icon: 'TagsIcon',
        children: {
            create: {
                path: '/tags/create',
                component: lazy(() => import('../pages/tags/CreateTag').then(module => ({ default: module.CreateTag }))),
                title: 'Crear Etiqueta',
            },
            edit: {
                path: '/tags/edit/:id',
                component: lazy(() => import('../pages/tags/CreateTag').then(module => ({ default: module.CreateTag }))),
                title: 'Editar Etiqueta',
            },
            view: {
                path: '/tags/view/:id',
                component: lazy(() => import('../pages/tags/CreateTag').then(module => ({ default: module.CreateTag }))),
                title: 'View Etiqueta',
            }
        }
    },
    taxClassifications: {
        path: '/tax-classifications',
        component: TaxClassifications,
        title: 'Clasificaciones de Impuestos',
        icon: 'TaxClassificationsIcon',
        children: {
            create: {
                path: '/tax-classifications/create',
                component: lazy(() => import('../pages/taxclassification/CreateTaxClassification').then(module => ({ default: module.CreateTaxClassification }))),
                title: 'Crear Clasificación',
            },
            edit: {
                path: '/tax-classifications/edit/:id',
                component: lazy(() => import('../pages/taxclassification/CreateTaxClassification').then(module => ({ default: module.CreateTaxClassification }))),
                title: 'Editar Clasificación',
            },
            view: {
                path: '/tax-classifications/view/:id',
                component: lazy(() => import('../pages/taxclassification/CreateTaxClassification').then(module => ({ default: module.CreateTaxClassification }))),
                title: 'View Clasificación',
            }
        }
    },
    taxes: {
        path: '/taxes',
        component: Taxes,
        title: 'Impuestos',
        icon: 'TaxesIcon',
        children: {
            create: {
                path: '/taxes/create',
                component: lazy(() => import('../pages/taxes/CreateTaxes').then(module => ({ default: module.CreateTax }))),
                title: 'Crear Impuesto',
            },
            edit: {
                path: '/taxes/edit/:id',
                component: lazy(() => import('../pages/taxes/CreateTaxes').then(module => ({ default: module.CreateTax }))),
                title: 'Editar Impuesto',
            },
            view: {
                path: '/taxes/view/:id',
                component: lazy(() => import('../pages/taxes/CreateTaxes').then(module => ({ default: module.CreateTax }))),
                title: 'Ver Impuesto',
            }
        }
    },
    users: {
        path: '/users',
        component: Users,
        title: 'Usuarios',
        icon: 'UsersIcon',
        children: {
            create: {
                path: '/users/create',
                component: lazy(() => import('../pages/users/CreateUsers').then(module => ({ default: module.CreateUser }))),
                title: 'Crear Usuario',
            },
            edit: {
                path: '/users/edit/:id',
                component: lazy(() => import('../pages/users/CreateUsers').then(module => ({ default: module.CreateUser }))),
                title: 'Editar Usuario',
            },
            view: {
                path: '/users/view/:id',
                component: lazy(() => import('../pages/users/CreateUsers').then(module => ({ default: module.CreateUser }))),
                title: 'Ver Usuario',
            }
        }
    },
    // ... más configuraciones de rutas
};

// Rutas públicas que no requieren autenticación
export const publicRoutes = {
    login: {
        path: '/',
        component: lazy(() => import('../pages/Login').then(module => ({ default: module.default }))),
        title: 'Iniciar Sesión',
    },
    register: {
        path: '/register',
        component: lazy(() => import('../pages/Register').then(module => ({ default: module.Register }))),
        title: 'Registro',
    },
    verifyEmail: {
        path: '/verificar-correo',
        component: lazy(() => import('../pages/EmailVerification')),
        title: 'Verificar Correo',
    },
};

// Tipos para TypeScript
export interface RouteConfig {
    path: string;
    component: React.LazyExoticComponent<any>;
    title: string;
    icon?: string;
    children?: {
        [key: string]: Omit<RouteConfig, 'icon' | 'children'>;
    };
}

export interface Routes {
    [key: string]: RouteConfig;
}
