import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import React, {ReactNode} from "react";
type DefaultLayoutProps = {
    children: ReactNode;
}
const PrivateRoute: React.FC<DefaultLayoutProps> = ({ children }) => {
    const token = useSelector((state: any) => state.login.token);

    // Si no hay token, redirige al usuario a la página de inicio de sesión.
    if (!token) {
        return <Navigate to="/" replace />;
    }

    return <>{children}</>;
};

export default PrivateRoute;
