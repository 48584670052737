// @ts-ignore
import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
// @ts-ignore
import axios from 'axios';
import { RootState } from '../store/store';
import { URL_BASE } from '../config/config';

interface User {
    id: string;
    name: string;
    email: string;
    role?: string;
    selectedCompany?: string;
}

interface Company {
    id: string;
    name: string;
    identification?: string;
    code?: string;
    type?: string;
    enabled?: boolean;
    created_at?: string;
    updated_at?: string;
    tax_classification_id?: string | null;
}

interface MenuItem {
    id: string;
    title: string;
    enabled: boolean;
    items: any[];
}

interface LoginState {
    user: User | null;
    companies: Company[];
    companiesAll: Company[];
    token: string | null;
    menu: MenuItem[];
    isLoading: boolean;
    error: string | null;
}

const initialState: LoginState = {
    user: null,
    companies: [],
    companiesAll: [],
    token: null,
    menu: [],
    isLoading: false,
    error: null
};

// Thunk para el login
export const loginUser = createAsyncThunk(
    'login/loginUser',
    async (credentials: { email: string; password: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post( `${URL_BASE}/login`, credentials);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Thunk para el logout
export const logout = createAsyncThunk(
    'login/logout',
    async (_, { rejectWithValue }) => {
        try {
            await axios.post('/api/logout');
            return null;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Selectores memorizados con valores por defecto
export const selectUser = (state: RootState) => state.login.user || null;
export const selectCompanies = (state: RootState) => state.login.companies || [];
export const selectAllCompanies = (state: RootState) => {
    const companies = state.login.companiesAll || [];
    console.log('All companies in selector:', companies);
    return companies;
};
export const selectToken = (state: RootState) => state.login.token || null;
export const selectMenu = (state: RootState) => state.login.menu || [];
export const selectLoading = (state: RootState) => state.login.isLoading;
export const selectError = (state: RootState) => state.login.error || null;

// Selector memorizado para la empresa seleccionada con mejor manejo de tipos
export const selectSelectedCompany = createSelector(
    [selectUser, selectCompanies],
    (user, companies): Company => {
        const defaultCompany: Company = { id: "", name: "Seleccionar Empresa" };
        if (!user || !companies.length) return defaultCompany;
        const selectedCompanyId = user.selectedCompany;
        const selectedCompany = companies.find((c) => c.id === selectedCompanyId);
        return selectedCompany || companies[0] || defaultCompany;
    }
);

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLoginData: (state, action: PayloadAction<any>) => {
            console.log('Setting login data with companies_all:', action.payload.companies_all);
            state.user = action.payload.user;
            state.companies = action.payload.company;
            state.companiesAll = action.payload.companies_all;
            state.token = action.payload.token;
            state.menu = action.payload.menu || [];
        },
        setSelectedCompany: (state, action: PayloadAction<string>) => {
            if (state.user) {
                state.user.selectedCompany = action.payload;
            }
        },
        clearLoginData: (state) => {
            state.user = null;
            state.companies = [];
            state.companiesAll = [];
            state.token = null;
            state.menu = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload.user;
                state.companies = action.payload.company;
                state.companiesAll = action.payload.companies_all;
                state.token = action.payload.token;
                state.menu = action.payload.menu || [];
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null;
                state.companies = [];
                state.companiesAll = [];
                state.token = null;
                state.menu = [];
            });
    }
});

export const { setLoginData, setSelectedCompany, clearLoginData } = loginSlice.actions;

export default loginSlice.reducer; 