// @ts-ignore
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../utils/apiClient'; // Suponiendo que tienes un cliente API configurado.

interface Category {
    id: string;
    name: string;
    description: string | null;
    enabled: boolean;
    is_service: boolean;
    created_at: string;
    updated_at: string | null;
    deleted_at: string | null;
}

interface CategoriesState {
    categories: Category[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: CategoriesState = {
    categories: [],
    status: 'idle',
    error: null,
};

export const fetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiClient.get('/categories?option=paginate&direction=desc&order_by=created_at');
            return response.data.data; // Ajusta según la estructura de tu API
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Error al obtener las categorías');
        }
    }
);

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories = action.payload;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export default categoriesSlice.reducer;
