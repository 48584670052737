import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from '../slices/LoginSlice';
import categoriesReducer from '../slices/CategoriesSlice';
import sidebarReducer from '../slices/SidebarSlice';

export interface RootState {
    login: ReturnType<typeof loginReducer>;
    categories: ReturnType<typeof categoriesReducer>;
    sidebar: ReturnType<typeof sidebarReducer>;
}

const rootReducer = combineReducers({
    login: loginReducer,
    categories: categoriesReducer,
    sidebar: sidebarReducer,
});

export default rootReducer;
